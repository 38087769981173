import React, { useState } from "react";
import Page1 from "./PrivacyPolicyVerbiage";
import InternalCustomerDetails from "./InternalCustomerDetails";

const PolicyDocument = ({ isInternal }) => {

  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
  };

  return (
    <>
    <div className="flex items-center justify-center h-2/4">
      <div className="policy-container bg-gray-200 p-4 rounded-md shadow max-w-3xl w-full">
        <div className="policy-content overflow-y-auto max-h-96">
          <Page1 />
        </div>
      </div>
    </div>
    {
    <InternalCustomerDetails />
    }
    </>
  );
};

export default PolicyDocument;
