
import React from 'react';
import { createContext, useState } from "react";
import Stepper from "../Forms/Privacy Policy/Stepper";
 
export const FormContext = createContext();

const Home = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  return (
    <FormContext.Provider value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
      <div className="h-screen flex flex-col items-center justify-start">
        <Stepper />
      </div>
    </FormContext.Provider>
  );
};
 
export default Home;