import React, { useContext } from "react";
import { FormContext } from "../../../App";
import InternalThankYouPage from './InternalThankYouPage';
import PolicyDocument from './PolicyDocument';

const InternalPolicyStepper = () => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <PolicyDocument />;
      break;
    case 1:
      stepContent = <InternalThankYouPage />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default InternalPolicyStepper;