import React from "react";

const PrivacyPolicyVerbiage = () => (
  <div className="page p-4 bg-white mb-4 rounded">
    <h2 className="text-lg font-semibold text-center">LEAF HOME</h2>
    <br />
    <h2 className="text-lg font-semibold text-center">
      JOB CANDIDATE, INDEPENDENT CONTRACTOR, & EMPLOYEE PERSONAL DATA PRIVACY
      POLICY
    </h2>
    <p>
      <br />
      <h3 className="text-md font-semibold">Introduction</h3>
      <br />
      <p>
        Leaf Home and its affiliates and subsidiaries (collectively, the
        “Company”, “we”, “our”, or “us”) are committed to maintaining our job
        applicants’, independent contractors’ and employees’ privacy.{" "}
      </p>
      <br />
      <p>
        This Personal Data Privacy Policy (the “Policy”) is intended to explain
        to you the practices regarding the collection and use of personal
        information we collect about you and from you. This Policy does not
        cover your use of our products as a consumer, outside of your employment
        with us. Our products may include services, websites, apps, software,
        servers, and devices. To learn more about our personal data practices
        that cover your use of our products, please read our Website Privacy
        Policy.{" "}
      </p>
      <br />
      <p>
        This Policy is not intended and shall not be read to create any express
        or implied promise or contract for employment, for any benefit, or for
        specific treatment in specific situations. Nothing in this notice should
        be construed to interfere with our ability to process personal data for
        purposes of complying with legal obligations or for investigating
        alleged misconduct or violations of Company policy or law, subject to
        compliance with local applicable law.{" "}
      </p>
      <br />
      <br />
      <h3 className="text-md font-semibold">Scope</h3>
      <br />
      This Policy applies to all Company job applicants, independent
      contractors, employees, and to the personal data of all individuals (“you”
      or “your”) who apply to be, are, or were, employed by the Company.
      <br />
      <br />
      <h3 className="text-md font-semibold">Definitions</h3>
      <br />
      <p>
        Except as otherwise defined in this Policy, capitalized terms have the
        meanings set forth herein.
      </p>
      <br />
      <p>
        “Personal Data” means any information about an identified or
        identifiable natural person, or that is reasonably linked to, or
        reasonably capable of being linked to, an identified or identifiable
        natural person; that is an employee or independent contractor of the
        Company or any individual who seeks to be employed by the Company in the
        context of a person’s employment or engagement with the Company.
        Personal Data excludes any information that is anonymous or
        de-identified and not otherwise associated with a particular individual.
      </p>
      <br />
      <p>
        “Identifiable natural person” means a person who can be identified,
        directly or indirectly, in particular by reference to an identification
        number or to one or more factors specific to his or her physical,
        physiological, mental, economic, cultural or social identity.{" "}
      </p>
      <br />
      <h3 className="text-md font-semibold">Compliance with Local Laws</h3>
      <br />
      <p>
        This Policy is meant to guide the Company with respect to Personal Data.
        However, the Company has operations in many states and jurisdictions
        which have varied laws, rules and regulations related to the privacy of
        Personal Data. It is the Company’s policy to comply with the privacy
        laws within each jurisdiction in which the Company operates.
      </p>
      <br />
      <p>
        <strong>
          While this Policy applies to Personal Data generally, the local laws,
          rules and regulations of jurisdictions that are applicable to the
          Company (“Local Laws”) may require standards which are stricter than
          this Policy and, in such event, the Company will comply with
          applicable Local Laws. Specific privacy policies and procedures may be
          adopted to address the specific privacy requirements of particular
          jurisdictions as is required by applicable Local Laws.
        </strong>
      </p>
      <br />
      <h3 className="text-md font-semibold">
        Collection and Use of Personal Data
      </h3>
      <br />
      <p>
        The Company collects or may collect Personal Data to conduct the
        business of the Company and comply with applicable laws, rules and
        regulations. The Company may use your Personal Data as set forth in this
        Policy (or any other Company policy, as applicable), and for any other
        purposes for which your consent is required under applicable law so long
        as the Company obtains your consent. The Company may use your Personal
        Data without your knowledge or consent where the Company is permitted or
        required to do so under applicable law.{" "}
      </p>
      <br />
      <p className="mb-4">
        The Company collects or may collect the following types of Personal Data
        depending upon your employment or prospective employment
        responsibilities, citizenship, location of employment, and other
        factors:
      </p>
      <ul className="list-disc list-inside mb-4 ml-8">
        <li>
          <strong>Identifiers:</strong> name including name history (e.g.,
          maiden name); phone numbers, email addresses, mailing addresses; zip
          code (postal); government identification numbers (e.g., social
          security numbers, taxpayer identification numbers, drivers' license
          numbers); date of birth; gender; race; ethnicity; family-related data
          (e.g., marital status and personal data);
        </li>
        <li>
          <strong>Internet and other Electronic Information:</strong> user IDs;
          data obtained by the Company in connection with the Company's exercise
          of its rights under its other policies such as its Website Privacy
          Policy (e.g., employee e-mail and data related to Internet use);
        </li>
        <li>
          <strong>Professional or Employment-Related Information:</strong>{" "}
          resumes and employment applications; references and interview notes;
          letters of offer and acceptance of employment; payroll information,
          wage and benefit information; compensation history; performance
          information; information related to employee health and other benefits
          including short and long term disability, medical and dental care;
          beneficiary information; documents and information related to proof of
          work eligibility; other data deemed to be necessary by the Company or
          voluntarily disclosed in the course of an employee's application for
          and employment with the Company;
        </li>
        <li>
          <strong>Education Information:</strong> education, training,
          qualifications, and other certifications; awards; professional
          memberships;
        </li>
        <li>
          <strong>Biometric Data:</strong> your facial geometry; biometric
          identifiers; biometric information;
        </li>
        <li>
          <strong>Other Information:</strong> photographs and video; banking and
          other financial data; health and disability data; emergency contact
          information; family-related data (e.g., health-related data on family
          members); veteran status;
        </li>
      </ul>
      <p className="mb-4">
        The Company may hold, use, and disclose your Personal Data for business
        purposes as are reasonably required by the Company. This includes,
        without limitation, holding, using, and disclosing your Personal Data
        for the following purposes:
      </p>
      <ul className="list-disc list-inside ml-8">
        <li>
          <strong>Managing the Employer Relationship:</strong> to determine your
          eligibility for employment with the Company including the verification
          of references and qualifications; to identify you; to communicate with
          you; to establish training and development requirements; to conduct
          performance reviews and determine performance requirements; to assess
          your qualifications for a particular job, task, or assignment; to
          gather evidence for disciplinary actions up to and including
          termination of employment; for human resources management purposes
          (e.g., the attraction, retention and motivation of the Company's
          workforce including the Company's recruitment, compensation,
          succession planning, performance assessment, training, and employee
          benefit administration efforts); to conduct data analytics and
          analysis to review employee retention, attrition rates, and other
          related statistics
        </li>
        <li>
          <strong>Administration of Benefits:</strong> to administer pay and
          benefits; to process work-related claims (e.g., workers' compensation
          and insurance claims); to provide employee benefits;
        </li>
        <li>
          <strong>Maintain Emergency and General Contacts:</strong> to establish
          a point of contact in the event of an emergency; to contact family or
          designated individuals; to compile directories;
        </li>
        <li>
          <strong>Safety and Security:</strong> to comply with laws, rules, or
          regulations; when, in the Company's judgment, disclosure is necessary
          to prevent fraud or to comply with any statute, law, rule, or
          regulation of any governmental authority or any order of any court of
          competent jurisdiction; to verify your identity for access to Company
          systems, networks, databases, software, hardware, or devices; to
          ensure network and information security, including preventing
          unauthorized access to our computer and electronic communications
          systems and preventing malicious software or code distribution;
        </li>
        <li>
          <strong>Business Transaction:</strong> to transfer Personal Data in
          connection with an asset sale, acquisition, merger, consolidation,
          reorganization, divestiture, stock sale, purchase of part or all of
          the assets of the Company or any of its subsidiaries or affiliates, or
          other transaction, or by way of assignment (whether by operation of
          law or otherwise) in connection with any such or similar transaction
          or in connection with the administration of a bankruptcy estate. In
          such an event, your Personal Data may become the property of the
          successor and will be subject to the successor's privacy policies.
        </li>
        <li>
          <strong>Legitimate Business Purposes:</strong> to provide and operate
          the Company's business processes, systems, and business operations
          (e.g., work assignments, management of Company property, reporting and
          releasing of public Company data, populating Company directories, and
          safety and protection of Company employees, directors, owners, agents,
          assets, resources, properties, and facilities); when the Company
          outsources some or all of its operations to third-party service
          providers who provide services for the Company; to third parties
          engaged by the Company and serving in an agency capacity to perform
          functions on behalf of the Company including, without limitation, the
          processing of personal data for compensation, the provision of
          employee benefits, the performance of legal and other professional
          services; in connection with a sale or other disposition of an
          affiliate or business unit; to enforce or apply the Company's policies
          and other agreements; to support internal administration with our
          affiliates.
        </li>
      </ul>
    </p>
    <br />
    <p className="text-md font-semibold">
      Collection and Use of Special Categories of Personal Data
    </p>
    <br />
    <p className="mb-4">
      Certain jurisdictions provide special categories of personal data that are
      considered sensitive under the applicable Local Laws. Company collects and
      uses the following special categories of personal data when you
      voluntarily provide them:
      <ul className="list-disc list-inside mb-4 ml-8">
        <li>Social Security number</li>
        <li>health and disability data</li>
        <li>biometric data</li>
        <li>race or ethnic origin</li>
      </ul>
    </p>
    <p className="mb-4">
      Company collects and uses the foregoing special categories of personal
      data for the following legitimate business purposes:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>to carry out our obligations under employment law</li>
      <li>
        for the performance of the hiring and employment contract and to provide
        services reasonably expected in the employee-employer or independent
        contractor relationship
      </li>
      <li>
        to protect the Company’s legal interests and to ensure the physical
        safety of the Company and other individuals
      </li>
      <li>as otherwise required by law</li>
    </ul>
    <p className="mb-4">
      Where Company has a legitimate need to process special categories of
      personal data for purposes not identified above and where required by
      Local Law, Company will only do so after providing you with notice and, if
      required by applicable Local Law, obtaining prior consent.
    </p>
    <p className="mb-4">
      With regard to biometric data, we collect, store, use, and otherwise
      process biometric data in accordance with the Biometric Data Policy (set
      forth below), which applies to all of our employees and to the biometric
      data of all of our employees.
    </p>
    <p className="text-md font-semibold">
      Information for California Residents:
    </p>
    <br />
    <p className="mb-4">
      The following section of this Policy only applies to employees,
      independent contractors, or prospective employees or independent
      contractors who are California residents.
    </p>
    <h3 className="text-lg font-bold mb-4">Collection</h3>
    <p className="mb-4">
      Consistent with the "Collection and Use of Personal Data" section above,
      the Company collects certain categories and specific pieces of Personal
      Data about employees who reside in California ("CA Personal Data"). In the
      prior 12 months, the Company collected the following types of categories
      of CA Personal Data, which the Company will continue to collect:
    </p>
    <table className="w-full mb-4">
      <thead>
        <tr>
          <th className="border border-gray-300 p-2">Personal Information</th>
          <th className="border border-gray-300 p-2">
            We collect the following subcategories of personal information:
          </th>
          <th className="border border-gray-300 p-2">
            We collect such personal information for the following purposes:
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border border-gray-300 p-2">Identifiers</td>
          <td className="border border-gray-300 p-2">
            Name, date of birth, staff member IDs, home and business address,
            telephone/email addresses, emergency contact details, national
            ID/passport, work permits, immigration/visa status, social security
            numbers
          </td>
          <td className="border border-gray-300 p-2">
            Employment and Work Related Purposes, Health and Safety Purposes,
            Law-Related Purposes, Corporate Transaction Purposes, IT and IT
            Security Purposes, Legitimate Business Purposes
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">
            Characteristics of protected classifications under California or
            federal law
          </td>
          <td className="border border-gray-300 p-2">
            Gender, age, nationality, race/ethnicity, citizenship, and health
            and medical information (including but not limited to, observations,
            inquiry responses and testing results)
          </td>
          <td className="border border-gray-300 p-2">
            Employment and Work Related Purposes, Health and Safety Purposes,
            Law-Related Purposes, Corporate Transaction Purposes
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">
            Internet or other electronic network activity information
          </td>
          <td className="border border-gray-300 p-2">
            IP addresses, log files, login information, and cookies and device
            information (e.g., online activity such as date and time of
            accessing our websites, website addresses accessed, the length of
            website visits)
          </td>
          <td className="border border-gray-300 p-2">
            IT and IT Security Purposes, Legitimate Business Purposes
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">
            Approximate Geolocation
          </td>
          <td className="border border-gray-300 p-2">
            IP Addresses and other internet or electronic network activity
            information (including, without limitation, such information as may
            be provided by your internet service provider)
          </td>
          <td className="border border-gray-300 p-2">
            IT and IT Security Purposes, Legitimate Business Interest Purposes
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">Biometric Data</td>
          <td className="border border-gray-300 p-2">
            Facial geometry; biometric identifiers, biometric information (see
            Biometric Data Policy below for more information)
          </td>
          <td className="border border-gray-300 p-2">
            IT and IT Security Purposes, Legitimate Business Interest Purposes
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">
            Audio, electronic, visual, thermal, olfactory, or similar
            information
          </td>
          <td className="border border-gray-300 p-2">
            Photographs and videos, thermal information
          </td>
          <td className="border border-gray-300 p-2">
            Health & Safety Purposes
          </td>
        </tr>
        <tr className="border border-gray-300">
          <td className="border border-gray-300 p-2">
            Professional or employment-related information
          </td>
          <td className="border border-gray-300 p-2">
            Employment details - job title/position, office location,
            employment-related agreements, performance and disciplinary records,
            grievance procedures, sickness/holiday records
            <br />
            <br />
            Reference and background information, and information obtained from
            criminal background checks
            <br />
            <br />
            Financial information - banking details, tax information,
            withholdings, salary, benefits, expenses, company allowances, equity
            and other long-term incentive grants
            <br />
            <br />
            Spouse and dependent information - marital status, household
            <br />
            <br />
            Education information - Academic/professional qualifications and
            education
          </td>
          <td className="border border-gray-300 p-2">
            Employment and Work Related Purposes, Law-Related Purposes,
            Corporate Transaction Purposes, Legitimate Business Purposes
            <br />
            <br />
            For vetting/screening, to the extent permissible and in accordance
            with applicable law, Corporate Transaction Purposes.
            <br />
            <br />
            Employment and Work Related Purposes, Law-related purposes,
            Corporate Transaction Purposes, Legitimate Business Purposes
            <br />
            <br />
            Employment and Work Related Purposes, Health and Safety Purposes,
            Corporate Transaction Purposes
          </td>
        </tr>

        <tr>
          <td className="border border-gray-300 p-2">Education information</td>
          <td className="border border-gray-300 p-2">
            Academic/professional qualifications and education
          </td>
          <td className="border border-gray-300 p-2">
            Employment and Work Related Purposes Law-Related Purposes Corporate
            Transaction Purposes
          </td>
        </tr>
      </tbody>
    </table>
    <p className="mb-4">
      <p className="mb-4 underline">Sources</p>
      <p>
        Consistent with the "Collection and Use of Personal Data" section above,
        the Company collects certain categories of CA Personal Data directly
        from employees and, in some circumstances, third parties. The categories
        of sources that the Company collected CA Personal Data from in the prior
        12 months includes the following:
      </p>
      <ul className="list-disc list-inside mb-4 ml-8">
        <li>
          The Company’s internal and external Website’s (including an employee’s
          email).
        </li>
        <li>The Company’s third party service providers.</li>
        <li>
          Job applications and other employee or independent contractor
          communication with the Company.
        </li>
      </ul>
    </p>
    <p className="mb-4">
      <p className="mb-4 underline">Disclosures to Third parties</p>
      <p>
        {" "}
        Consistent with the "Disclosure of Personal Data to Third Parties"
        section above, the Company discloses certain categories of CA Personal
        Data with third parties pursuant to the purposes listed in this Policy.
        The categories of CA Personal Data that the Company disclosed to third
        parties who are considered "service providers" (as defined under
        California law) in the prior 12 months includes the following:
      </p>
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>
        Personal identification information such as real name, postal address,
        online identifier, and email address.
      </li>
      <li>Payroll information.</li>
      <li>Wage and benefit information.</li>
      <li>Demographic information.</li>
      <li>
        Information related to employee health and other benefits including
        short and long term disability, medical and dental care.
      </li>
      <li>Beneficiary and emergency contact information.</li>
      <li>Biometric data.</li>
      <li>Approximate Geographical location.</li>
    </ul>
    <p className="mb-4">
      The categories of third parties that the Company has disclosed CA Personal
      Data to in the prior 12 months includes the following:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>Third-party service providers.</li>
      <li>Company affiliates and subsidiaries.</li>
      <li>Law enforcement.</li>
      <li>Company affiliates.</li>
    </ul>
    <p className="mb-4">
      <p className="mb-4 underline">California Privacy Rights</p>
      <p>
        The California Consumer Privacy Act, including as amended by the
        California Privacy Rights Act (the "CCPA") grants individuals, that are
        California residents, certain rights (subject to possible exemptions and
        exceptions) in regard to our collection of Personal Data. These rights
        include, only to the extent applicable and to the extent granted to you
        under the CCPA:
      </p>
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>
        The right to request the disclosures of (1) what personal information
        has been collected; (2) the categories of sources from which your
        personal information was collected; (3) the business or commercial
        purpose for collection or selling your personal data; (4) the categories
        of third parties with whom we disclose personal information; and (5) the
        specific pieces of personal information we have collected about you
        (also called data portability);
      </li>
      <li>
        The right to request the deletion of your personal information that we
        have collected;
      </li>
      <li>
        The right to request the correction of your personal information that we
        have collected; and
      </li>
      <li>
        The right to not be discriminated against due to your exercise of your
        rights under the CCPA. We do not discriminate based on an individual's
        exercise of the rights granted to them under the CCPA.
      </li>
    </ul>
    <p className="mb-4">
      The above rights are only exercisable by you where the CCPA grants you the
      right being exerted and where no exception or exemption under the CCPA
      applies. You may also have a registered agent (if and only as permitted
      under applicable law) that you authorize to act on your behalf. If you
      have a registered agent act on your behalf, we have the right to
      authenticate such agent's authority to act.
    </p>
    <p className="mb-4">
      We do not sell your personal information to third parties, nor do we share
      your information for purposes of cross-contextual behavioral advertising
      as defined under the CCPA. In the event we begin selling personal
      information (1) this Policy will be updated to reflect that fact and you
      will be notified of such update and (2) if required under the CCPA, you
      will be provided the ability to opt out of the sale of your personal
      information or the sharing of your personal information for
      cross-contextual behavioral advertising purposes (as may be applicable).
    </p>
    <p className="mb-4">
      To exercise the rights described herein (only to the extent applicable),
      you may submit a verifiable request to us by through the methods set forth
      under the "Exercise Your Rights" section below. We will respond to all
      requests that we receive from you about your personal information in
      accordance with applicable data protection laws and to the extent
      permissible under any other applicable laws.
    </p>
    <p className="mb-4 underline">Exercising Your Rights</p>
    <p className="mb-4">
      To exercise the rights described above only to the extent applicable law
      grants you such rights, please submit a verifiable consumer request to us
      by either:
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>Calling: [Phone Number]</li>
      <li>Visiting: [Website]</li>
      <li>Emailing: [Email Address]</li>
    </ul>
    <p className="mb-4">
      Only you or a registered agent (if and only as permitted under applicable
      law) that you authorize to act on your behalf may make a verifiable
      consumer request related to your personal information. If you have a
      registered agent act on your behalf, we have the right to authenticate
      such agent's authority to act. You may only make a verifiable consumer
      request for access or data portability twice within a 12-month period.
      Without limiting the foregoing, the verifiable consumer request must:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>
        Provide sufficient information that allows us to reasonably verify you
        are the person about whom we collected personal information or an
        authorized representative.
      </li>
      <li>
        Describe your request with sufficient detail that allows us to properly
        understand, evaluate, and respond to it.
      </li>
    </ul>
    <p className="mb-4">
      We cannot respond to your request or provide you with personal information
      if we cannot verify your identity or authority to make the request and
      confirm the personal information relates to you. Making a verifiable
      consumer request does not require you to create an account with us. We
      will only use personal information provided in a verifiable consumer
      request to verify the requestor's identity or authority to make the
      request.
    </p>
    <p className="mb-4">
      We endeavor to respond to a verifiable consumer request within 45 days of
      its receipt, and in any event, we will respond to you within the timeframe
      required under applicable law. We do not charge a fee to process or
      respond to your verifiable consumer request unless it is excessive,
      repetitive, or manifestly unfounded. If we determine that the request
      warrants a fee, we will tell you why we made that decision and provide you
      with a cost estimate before completing your request.
    </p>
    <p className="mb-4">
      <p className="mb-4 underline">Children </p>
      <p>
        The Company's data collection doe pursuant to this Policy is not direct
        at children under 16 years of age. We do not knowingly collect or use
        information from children under 16 years of age in our data collection
        under this Policy.
      </p>
    </p>
    <h3 className="text-md font-bold mb-4 underline">Biometric Data Policy</h3>
    <p className="mb-4">
      Leaf Home and its affiliates and subsidiaries (collectively, "Leaf Home,"
      "we," "us," or "our") have instituted the following biometric information
      privacy policy (the "Biometric Data Policy") with regard to our collection
      and use of our employees' and independent contractor's biometric data.
    </p>
    <p className="mb-4">
      This Biometric Data Policy is intended to explain to you the practices
      regarding the collection and use of biometric data we collect about you
      and from you. This Biometric Data Policy applies to all of our employees
      and to the biometric data of all of our employees and independent
      contractors.
    </p>
    <h4 className="mb-4 underline">Definitions</h4>
    <p className="mb-4">
      As used in this policy, biometric data includes "biometric identifiers"
      and "biometric information" as each term may be defined under applicable
      law (including in the Illinois Biometric Information Privacy Act, 740 ILCS
      § 14/1, et seq.).
    </p>
    <p className="mb-4">
      "Biometric identifier" includes a retina or iris scan, fingerprint,
      voiceprint, or scan of hand or face geometry. Biometric identifiers do not
      include writing samples, written signatures, photographs, human biological
      samples used for valid scientific testing or screening, demographic data,
      tattoo descriptions, or physical descriptions such as height, weight, hair
      color, or eye color. Biometric identifiers do not include information
      captured from a patient in a health care setting or information collected,
      used, or stored for health care treatment, payment, or operations under
      the federal Health Insurance Portability and Accountability Act of 1996.
    </p>
    <p className="mb-4">
      "Biometric information" includes any information, regardless of how it is
      captured, converted, stored, or shared, based on an individual's biometric
      identifier used to identify an individual. Biometric information does not
      include information derived from items or procedures excluded under the
      definition of biometric identifiers.
    </p>
    <h4 className="underline mb-4">Collection and Use of Biometric Data</h4>
    <p className="mb-4">
      We, through our service providers, collect, store, and use biometric
      data—including, for example through facial recognition software—only for
      the following purposes:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>Employee and independent contractor identification;</li>
      <li>Fraud prevention;</li>
      <li>To prevent and detect security incidents;</li>
      <li>To resist illegal actions directed at us; and</li>
      <li>
        Verify and maintain the quality and safety of our devices and services.
      </li>
    </ul>
    <p className="mb-4">
      In furtherance of the purposes stated above, we engage biometric data
      vendors/licensors. Other than our biometric data vendors/licensors who
      provide us services for the above-stated purposes, we will not disclose or
      disseminate any biometric data to third parties without or unless:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>
        We first obtain consent prior to such disclosure or dissemination;
      </li>
      <li>
        The disclosed data completes a financial transaction request or
        authorized by you;
      </li>
      <li>Disclosure is required by applicable law; or</li>
      <li>
        Disclosure is required pursuant to a valid warrant or subpoena issued by
        a court of competent jurisdiction.
      </li>
    </ul>
    <p className="mb-4">
      Neither Leaf Home nor its biometric data vendors/licensors use or retain
      biometric data for commercial purposes.
    </p>
    <h4 className="underline mb-4">Retention of Biometric Data</h4>
    <p className="mb-4">
      Leaf Home, through its biometric data vendors / licensors, will retain
      employee and independent contractor biometric data collected pursuant to
      this Biometric Data Policy until the earlier of the following occur:
    </p>
    <ul className="list-disc list-inside mb-4 ml-8">
      <li>
        - The initial purpose for collecting or obtaining such biometric data is
        satisfied, such as termination of the employment relationship or
        independent contractor relationship, or
      </li>
      <li>- Within 3 years of the employee’s last interaction with us</li>
    </ul>
    <h4 className="underline mb-4">Monitoring by the Company</h4>
    <p className="mb-4">
      The Company reserves the right to monitor the activities of the Company’s
      employees during the course and scope of their employment for the Company
      including, without limitation, employee work emails and Internet use
      during working hours. All work product generated by employees, in whatever
      form (i.e., paper records, computer files or other format) is the property
      of the Company.{" "}
      <strong>
        Employees are advised that they should have no expectation of privacy in
        their stored or transmitted electronic communications through their
        office devices and that the Company may access these communications and
        that they should have no expectation of privacy with respect to these
        communications or their use of Company supplied equipment, communication
        systems, and other resources.
      </strong>
    </p>
    <p className="mb-4">
      The Company may also monitor employee and independent contractor
      activities on the Company’s facilities and property. Some of the Company’s
      facilities are equipped with surveillance cameras. These are generally
      used for the protection of the safety of employees and third parties, to
      protect against theft, vandalism, and damage to the Company’s facilities
      and property. Except as otherwise prohibited by applicable law (including,
      without limitation, applicable law) recorded images and video may be used
      by the Company as the Company deems appropriate for the protection of the
      safety of the Company’s employees and third parties, and to protect
      against theft, vandalism, and damage to the Company’s facilities and
      property including disclosing such images and video to law enforcement
      authorities or appropriate governmental agencies or authorities.
    </p>
    <h4 className="underline mb-4">Data Retention and Destruction</h4>
    <p className="mb-4">
      Company will retain Personal Data for as long as it is needed for or
      otherwise serves the purposes outline in this Policy, subject to
      applicable law.
    </p>
    <p className="mb-4">
      Company retains Personal Data in accordance with Company policies and
      practices, applicable law (including without limitation Local Laws) and
      contractual obligations. If there is no activity in relation to the
      Personal Data, Company removes it from its database, subject to its
      internal policies and procedures (including, without limitation, its
      regular backup and archival procedures and programs), and any applicable
      legal or regulatory obligations or for the period of time permitted by
      Local Laws.
    </p>
    <h4 className="underline mb-4">Accuracy & Security</h4>
    <p className="mb-4">
      It is every job candidate’s, independent contractor’s, and employee’s
      responsibility to provide the Company with accurate Personal Data. An
      employee must notify Company when there are changes so Company is above to
      ensure the personal data collected is reliable for its intended use.
    </p>
    <p className="mb-4">
      The Company takes reasonable steps to protect Personal Data in its
      possession from loss, misuse, unauthorized access, disclosure, alteration,
      and destruction. The Company has put in place reasonable and appropriate
      technical, physical, and organizational procedures and security measures
      designed to safeguard and secure the personal data from destruction, loss,
      alteration, unauthorized access or disclosure, or other forms of
      unauthorized or unlawful processing commensurate with the risks posed by
      the particular type of processing, the nature of the Personal Data and in
      accordance with applicable law, and taking into consideration the cost of
      implementing such measures.
    </p>
    <p className="mb-4">
      The Company values employees’ trust in providing us Personal Data;
      however, no methord of transmission over the Internet, or method of
      electronic storage is 100% secure and reliable. The Company cannot
      guarantee the security of personal data on or transmitted via the
      Internet.
    </p>
    <p className="mb-4">
      The Company limits access to its systems that hold certain Personal Data
      to authorized Company employees, representatives, and agents who are
      provided access through a password protection system. Access to such
      Personal Data is limited to Company employees, representatives, and agents
      who have a need to know such information for purposes of performing their
      job functions or as otherwise permitted under this Policy.
    </p>

    <h4 className="text-bold underline mb-4">Compliance</h4>
    <p className="mb-4">
      The Company will use a self-assessment approach to verify compliance with
      this Policy and periodically verify that the Policy is accurate,
      comprehensive for the information intended to be covered, prominently
      displayed, implemented and accessible.
    </p>
    <p className="mb-4">
      If you believe that your Personal Data has been processed or disclosed in
      violation of this Policy, the Company encourages you to raise any concerns
      using the contact information provided in this Policy. The Company will
      reasonably investigate and attempt to resolve any complaints and disputes
      regarding use and disclosure of Personal Data.
    </p>
    <p className="mb-4">
      Any employee of the Company that the Company determines is in violation of
      this Policy will be subject to disciplinary action up to and including
      termination of employment or a denial of their application for employment.
    </p>
    <h4 className="text-bold underline mb-4">Third Party Privacy Policies</h4>
    <p className="mb-4">
      Over the course of a job applicant’s, independent contractor’s, and
      employee’s relationship with the Company, a job candidate, independent
      contractor, or employee may enter third party websites, use third party
      provided software applications or platforms, or otherwise be subject to
      third party privacy policies. This Policy only pertains to the Company’s
      collection, use, or disclosure of Personal Data and does not apply to a
      third party’s collection, use, or disclosure of job candidate, independent
      contractor, or employee Personal Data. If a employee clicks on a link to a
      third party website or is subject to a third party privacy policy, the
      employee should read that privacy policy.
    </p>
    <h4 className="text-bold underline mb-4">Changes to this Policy</h4>
    <p className="mb-4">
      This Policy applies from [ ]. Company reserves the right to amend the
      Policy from time to time, consistent with the requirements of applicable
      law. Any updated version of this Policy will be made available on this
      site.
    </p>
    <h4 className="text-bold underline mb-4">Contacting the Company</h4>
    <p className="mb-4">
      If you have questions or concerns regarding this Policy, please contact
      the Company through the contact methods below:
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>Phone: [Phone Number]</li>
      <li>Email: [Email Address]</li>
      <li>Address: [Address]</li>
    </ul>
  </div>
);

export default PrivacyPolicyVerbiage;
