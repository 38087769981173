import React, { useContext } from "react";
import { FormContext } from "../../../App";
import ThankYouPage from './ThankYouPage';
import PolicyDocument from './PolicyDocument';

const Stepper = () => {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <PolicyDocument />;
      break;
    case 1:
      stepContent = <ThankYouPage />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default Stepper;