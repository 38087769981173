import React, { useState } from "react";
import Page from "./PrivacyPolicy2024January";
import CustomerDetails from "./CustomerDetails";

const PolicyDocument = ({ isInternal }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
  };

  return (
    <>
    <div className="flex items-center justify-center Xh-2/4">
      <div className="policy-container bg-gray-200 p-4 rounded-md shadow max-w-3xl w-full ">
        <div className="policy-content overflow-y-auto max-h-96">
          <Page />
        </div>
      </div>
    </div>
    <CustomerDetails />
    </>
  );
};

export default PolicyDocument;
