import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../App";
import InputMask from "react-input-mask";
import * as yup from "yup";
import axios from "axios";

const InternalCustomerDetails = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  let postUrl = process.env.REACT_APP_POST_URL ? process.env.REACT_APP_POST_URL : null;
  if( !postUrl ) {
    const queryParams = new URLSearchParams(window.location.search);
    const useProd = queryParams.get("useProd");

    if( useProd == null && window.location.hostname.match(/privacy-uat/) !== null ) {
      postUrl = "https://fa-devqa-uat-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=TOFaNqzZNKFuOR8tiIabAr9L/jAXsfPHJHvm3a9nYViC7Qvs5arahA==&clientId=aurora";
    } else if( useProd == null && window.location.hostname.match(/privacy-dev/) !== null ) {
      postUrl = "https://fa-devqa-dev-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=-eh-2O1VYv7X87sLsoZavJ-z5jZW8A7eROcvfD_Qjn6sAzFuk39e0g==&clientId=aurora";
    } else {
      postUrl = "https://fa-prod-prod-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=2sS04AW7SnxTM2dFwmKsE4XDIAcu8O9U/087wlyLIHTHk//XlIAFHQ==&clientId=aurora";
    }
  }
  
  const renderError = (fieldName) => (
    <ErrorMessage name={fieldName} component="p" className="text-red-600 font-bold" />
  );

  const ValidationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    emailAddress: yup.string().required().email("Invalid email format"),
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid")
      .required("Phone Number is required"),
    address: yup.string().required("Street Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State/Province is required"),
    zipCode: yup.string().required("ZipCode is required"),
    description: yup.string().required("Description of Request is required"),
    declareInformation: yup.boolean().oneOf([true], "You must declare the information"),
  });

  const handleSubmit = async (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
    try {
        // Simulate sending email to Service Admin
        await axios.post(postUrl, data);
      } catch (error) {
        console.error("Error sending email:", error);
      }
  };

  const handleRightsChange = (fieldName, value, setFieldValue) => {
    setFieldValue(`rights.${fieldName}`, value);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        makingRequestOnBehalf: false,
        makingRequestAsAuthorizedAgent: false,
        rights: {
          confirm: false,
          access: false,
          deletion: false,
          correction: false,
          withdrawConsent: false,
          optOutAdvertising: false,
          optOutSale: false,
          optOutProfiling: false,
          appeal: false,
        },
        description: "",
        declareInformation: false,
        isInternal: true,
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form autoComplete="off" className="mt-12">
          <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
          <div className="text-3xl font-medium self-center mb-8 mt-6">
             Private Data Request
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                First Name<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="firstName"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your First Name"
                autoComplete="off"
              />
              {renderError("firstName")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                Last Name<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="lastName"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your Last Name"
                autoComplete="off"
              />
              {renderError("lastName")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">Email Address<span className="text-red-500 font-bold"> *</span></label>
              <Field
                name="emailAddress"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Please Enter Your Email Address"
                autoComplete="off"
              />
              {renderError("emailAddress")}
            </div>
            <div className="mb-4">
            <label className="font-medium text-gray-900">Phone Number<span className="text-red-500 font-bold"> *</span></label>
                <Field name="phoneNumber">
                  {({ field }) => (
                    <InputMask
                      {...field}
                      className="rounded-md border-2 p-2 w-full"
                      mask="(999) 999-9999"
                      maskChar=" "
                    />
                  )}
                </Field>
              {renderError("phoneNumber")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                Street Address<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="address"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your Street Address"
                autoComplete="off"
              />
              {renderError("address")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                City<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="city"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your City"
                autoComplete="off"
              />
              {renderError("city")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                State/Province<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="state"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your State/Province"
                autoComplete="off"
              />
              {renderError("state")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                ZipCode<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                name="zipCode"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Your ZipCode"
                autoComplete="off"
              />
              {renderError("zipCode")}
            </div>
            <div className="mb-4">
              <label className="font-medium text-gray-900">
                Description of Request<span className="text-red-500 font-bold"> *</span>
              </label>
              <Field
                as="textarea"
                name="description"
                className="rounded-md border-2 p-2 w-full"
                placeholder="Enter Description of Request"
                autoComplete="off"
              />
              {renderError("description")}
            </div>
            <div className="mb-4">
              <div>
                <Field name="makingRequestOnBehalf">
                  {({ field, form }) => (
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={field.value}
                      onChange={() => {
                        form.setFieldValue("makingRequestOnBehalf", !field.value);
                        form.setFieldValue("makingRequestAsAuthorizedAgent", false);
                      }}
                    />
                  )}
                </Field>
                <label
                  className= "font-medium text-gray-900 cursor-pointer"
                  onClick={() => {
                    setFieldValue("makingRequestOnBehalf", !values.makingRequestOnBehalf);
                    setFieldValue("makingRequestAsAuthorizedAgent", false);
                  }}
                >
                  Making Request on My Own Behalf
                </label>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <Field name="makingRequestAsAuthorizedAgent">
                  {({ field, form }) => (
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={field.value}
                      onChange={() => {
                        form.setFieldValue("makingRequestAsAuthorizedAgent", !field.value);
                        form.setFieldValue("makingRequestOnBehalf", false);
                      }}
                    />
                  )}
                </Field>
                <label
                  className="font-medium text-gray-900 cursor-pointer"
                  onClick={() => {
                    setFieldValue("makingRequestAsAuthorizedAgent", !values.makingRequestAsAuthorizedAgent);
                    setFieldValue("makingRequestOnBehalf", false);
                  }}
                >
                  Making Request on Another's Behalf As Authorized Agent
                </label>
              </div>
            </div>
            <div className="flex flex-row items-center mb-4">
              <div>
                <label className="font-medium text-gray-900">
                  Rights<span className="text-red-500 font-bold"> *</span>
                </label>
                <div className="ml-8">
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.confirm"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("confirm", !values.rights.confirm, setFieldValue)
                      }
                    >
                      Right to Confirm
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.access"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("access", !values.rights.access, setFieldValue)
                      }
                    >
                      Right to Request Access (Data Portability)
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.deletion"
                      className="mr-2"
                    />
                    <label
                     className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("deletion", !values.rights.deletion, setFieldValue)
                      }
                    >
                      Right to Request Deletion
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.correction"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("correction", !values.rights.correction, setFieldValue)
                      }
                    >
                      Right to Request Correction
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.withdrawConsent"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange(
                          "withdrawConsent",
                          !values.rights.withdrawConsent,
                          setFieldValue
                        )
                      }
                    >
                      Right to Withdraw Consent (Where consent was the basis for collection or processing)
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.optOutAdvertising"
                      className="mr-2"
                    />
                    <label
                      className= "font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange(
                          "optOutAdvertising",
                          !values.rights.optOutAdvertising,
                          setFieldValue
                        )
                      }
                    >
                      Right to Opt-Out of Targeted Advertising
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.optOutSale"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("optOutSale", !values.rights.optOutSale, setFieldValue)
                      }
                    >
                      Right to Opt-Out of Sale
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.optOutProfiling"
                      className="mr-2"
                    />
                    <label
                    className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange(
                          "optOutProfiling",
                          !values.rights.optOutProfiling,
                          setFieldValue
                        )
                      }
                    >
                      Right to Opt-Out of Automated Profiling in Furtherance of Legally Significant or Similar Decisions
                    </label>
                  </div>
                  <div>
                    <Field
                      type="checkbox"
                      name="rights.appeal"
                      className="mr-2"
                    />
                    <label
                      className="font-medium text-gray-900 cursor-pointer"
                      onClick={() =>
                        handleRightsChange("appeal", !values.rights.appeal, setFieldValue)
                      }
                    >
                      Right to Appeal
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Field
                type="checkbox"
                name="declareInformation"
                className="mr-2"
              />
              <label className="font-medium text-gray-900 cursor-pointer"
                onClick={() => {
                    setFieldValue("declareInformation", !values.declareInformation);
                  }}
            >
                I Declare that the information given by me is correct to the best of my knowledge, and that I am entitled to make the request identified above under the laws applicable to me
              </label>
              {renderError("declareInformation")}
            </div>
            <div className="flex justify-center">
              <button
                className="rounded-md bg-cyan-500 hover:bg-cyan-600 font-medium text-white my-2 p-2"
                type="submit"
              >
                Rights Request Submission
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InternalCustomerDetails;

